import RouterView from "@/components/RouterView";
const menuList = [{
    path: "/home",
    name: "Home",
    component: RouterView,
    redirect: "/home",
    meta: {
      title: "首页",
      isMenu: true
    },
    children: [{
      path: "/home",
      component: () => import("@/views/Home/home.vue")
    }]
  },
  {
    path: "/enterprise",
    name: "Enterprise",
    component: RouterView,
    redirect: "/enterprise",
    meta: {
      title: "数智企业",
      isMenu: true
      // keepAlive: true,
      // isBack: false
    },
    children: [{
        path: "/enterprise",
        component: () => import("@/views/Enterprise")
      },
      {
        path: "enterpriseInfo",
        name: "EnterpriseInfo",
        component: () => import("../views/Enterprise/enterpriseInfo"),
        meta: {
          title: "数智企业详情",
          isMenu: true
        }
      }
    ]
  },
  {
    path: "/talents",
    name: "Talents",
    component: RouterView,
    redirect: "/talents",
    meta: {
      title: "人才数据",
      isMenu: true
    },
    children: [{
        path: "/talents",
        name: "Talents",
        component: () => import("@/views/Talents")
      },
      {
        path: "/editTalentQY",
        component: () => import("@/views/Talents/editTalentQY/editMain.vue"),
        name: "editTalentQY",
        meta: {
          title: "企业人才信息",
          isMenu: true
        }
      },
      {
        path: "/editTalentXS",
        component: () => import("@/views/Talents/editTalentXS/editMain.vue"),
        name: "editTalentXS",
        meta: {
          title: "学术人才信息",
          isMenu: true
        }
      }
    ]
  },
  {
    path: "/product",
    name: "Product",
    component: RouterView,
    redirect: "/product",
    meta: {
      title: "产品数据",
      isMenu: true
    },
    children: [{
        path: "/product",
        name: "Product",
        component: () => import("@/views/Product"),
      },
      {
        path: "/productInfo",
        name: "ProductInfo",
        component: () => import("../views/Product/editProduct"),
        meta: {
          // title: "基本信息",
          title: "产品数据详情",
          isMenu: true
        }
      }
    ]
  },
  {
    path: "/numberProject",
    name: "NumberProject",
    component: RouterView,
    redirect: "/projectData",
    meta: {
      title: "数智化项目",
      isMenu: true
    },
    children: [{
        path: "projectData",
        name: "ProjectData",
        component: RouterView,
        redirect: "/numberProject/projectData",
        meta: {
          title: "项目数据",
          isMenu: true
        },
        children: [{
            path: "/numberProject/projectData",
            // name: 'ProjectData',
            component: () => import("@/views/NumberProject/projectData")
          },
          {
            path: "/numberProject/projectDataInfo",
            name: "ProjectDataInfo",
            component: () =>
              import("../views/NumberProject/projectData/projectDataInfo"),
            meta: {
              title: "项目数据信息",
              isMenu: true
            }
          }
        ]
      },
      {
        path: "/demandAgency",
        name: "DemandAgency",
        component: RouterView,
        redirect: "/numberProject/demandAgency",
        meta: {
          title: "需求机构",
          isMenu: true
        },
        children: [{
            path: "/numberProject/demandAgency",
            name: "DemandAgency",
            component: () => import("../views/NumberProject/demandAgency")
          },
          {
            path: "/numberProject/viewDemandAgency",
            name: "ViewDemandAgency",
            component: () =>
              import("../views/NumberProject/demandAgency/ViewDemandAgency"),
            meta: {
              title: "需求机构项目",
              isMenu: true
            }
          }
        ]
      },
      {
        path: "/feedMechanism",
        name: "FeedMechanism",
        component: RouterView,
        redirect: "/numberProject/feedMechanism",
        meta: {
          title: "供给机构",
          isMenu: true
        },
        children: [{
            path: "/numberProject/feedMechanism",
            name: "FeedMechanism",
            component: () => import("../views/NumberProject/feedMechanism")
          },
          {
            path: "/numberProject/viewFeedMechanism",
            name: "ViewFeedMechanism",
            component: () =>
              import("../views/NumberProject/feedMechanism/ViewFeedMechanism"),
            meta: {
              title: "供给机构项目",
              isMenu: true
            }
          }
        ]
      }
      // {
      //   path: '/channelInstitutions',
      //   name: 'ChannelInstitutions',
      //   component: RouterView,
      //   redirect: '/numberProject/channelInstitutions',
      //   meta: {
      //     title: '渠道机构',
      //     isMenu: true
      //   },
      //   children: [
      //     {
      //       path: '/numberProject/channelInstitutions',
      //       name: 'ChannelInstitutions',
      //       component: () =>
      //         import('../views/NumberProject/channelInstitutions'),
      //     },
      //     {
      //       path: '/numberProject/viewChannelInstitutions',
      //       name: 'ViewChannelInstitutions',
      //       component: () =>
      //         import('../views/NumberProject/channelInstitutions/ViewChannelInstitutions'),
      //       meta: {
      //         title: '渠道机构项目',
      //         isMenu: true
      //       },
      //     },
      //   ]
      // },
    ]
  },
  {
    path: "/researchReport",
    name: "ResearchReport",
    component: RouterView,
    redirect: "/reportData",
    meta: {
      title: "研究报告",
      isMenu: true
    },
    children: [{
        path: "reportData",
        name: "ReportData",
        component: RouterView,
        redirect: "/researchReport/reportData",
        meta: {
          title: "报告数据",
          isMenu: true
        },
        children: [{
            path: "/researchReport/reportData",
            component: () => import("@/views/ResearchReport/ReportData")
          },
          {
            path: "/researchReport/tabIndexReportData",
            name: "tabIndexReportData",
            component: () =>
              import("../views/ResearchReport/ReportData/tabIndex"),
            meta: {
              title: "报告详细信息",
              isMenu: true
            }
          }
          // {
          //   path: "/researchReport/reportDataDetails",
          //   name: "ReportDataDetails",
          //   component: () =>
          //     import("../views/ResearchReport/ReportData/ReportDataDetails"),
          //   meta: {
          //     title: "报告基本信息",
          //     isMenu: true
          //   }
          // }
        ]
      },
      {
        path: "reportingInformation",
        name: "ReportingInformation",
        component: RouterView,
        redirect: "/researchReport/reportingInformation",
        meta: {
          title: "报告机构信息",
          isMenu: true
        },
        children: [{
            path: "/researchReport/reportingInformation",
            component: () =>
              import("@/views/ResearchReport/ReportingInformation")
          },
          {
            path: "/researchReport/reportingNumber",
            name: "ReportingNumber",
            component: () =>
              import(
                "../views/ResearchReport/ReportingInformation/ReportingNumber"
              ),
            meta: {
              title: "报告机构信息",
              isMenu: true
            }
          }
        ]
      },
      {
        path: "reportPictures",
        name: "ReportPictures",
        component: RouterView,
        redirect: "/researchReport/reportPictures",
        meta: {
          title: "报告图表",
          isMenu: true
        },
        children: [{
            path: "/researchReport/reportPictures",
            component: () => import("@/views/ResearchReport/ReportPictures")
          },
          {
            path: "/researchReport/reportPicturesInfo",
            name: "ReportPicturesInfo",
            component: () =>
              import("../views/ResearchReport/ReportPictures/info"),
            meta: {
              title: "报告图表信息",
              isMenu: true
            }
          }
        ]
      }
    ]
  },
  {
    path: "/policyData",
    name: "PolicyData",
    component: RouterView,
    redirect: "/policyData",
    meta: {
      title: "政策数据",
      isMenu: true
    },
    children: [{
        path: "/policyData",
        component: () => import("../views/PolicyData")
      },
      {
        path: "PolicyDataIndex",
        name: "PolicyDataIndex",
        component: () => import("../views/PolicyData/tabIndex.vue"),
        meta: {
          title: "政策数据信息",
          isMenu: true
        }
      }
      // 修改前的
      // {
      //   path: "PolicyDataInfo",
      //   name: "PolicyDataInfo",
      //   component: () => import("../views/PolicyData/PolicyDataInfo.vue"),
      //   meta: {
      //     title: "政策数据信息",
      //     isMenu: true
      //   }
      // }
    ]
  },
  {
    path: "/caseData",
    name: "CaseData",
    component: RouterView,
    redirect: "/caseData",
    meta: {
      title: "案例数据",
      isMenu: true
    },
    children: [{
        path: "/caseData",
        component: () => import("../views/CaseData")
      },
      {
        path: "/caseDataParent",
        name: "CaseDataParent",
        component: () => import("../views/CaseData/caseDataParent.vue"),
        meta: {
          title: "案例信息",
          isMenu: true
        }
      }
      // {
      //   path: "caseDataInfo",
      //   name: "CaseDataInfo",
      //   component: () => import("../views/CaseData/CaseDataInfo.vue"),
      //   meta: {
      //     title: "案例数据信息",
      //     isMenu: true
      //   }
      // }
    ]
  },
  {
    path: "/news",
    name: "news",
    component: RouterView,
    redirect: "/newsInformation",
    meta: {
      title: "新闻资讯",
      isMenu: true
    },
    children: [{
        path: "/newsInformation",
        name: "NewsInformation",
        component: RouterView,
        redirect: "/news/newsInformation",
        meta: {
          title: "资讯数据",
          isMenu: true
        },
        children: [{
            path: "/news/newsInformation",
            component: () => import("@/views/news")
          },
          {
            path: "/news/newsInformation/newsInfo",
            component: () => import("@/views/news/newsInfo"),
            name: "NewsInformationInfo",
            meta: {
              title: "查看资讯详情",
              isMenu: true
            }
          }
        ]
      },
      {
        path: "/newsSource",
        name: "NewsSource",
        component: RouterView,
        redirect: "/news/newsSource",
        meta: {
          title: "资讯来源",
          isMenu: true
        },
        children: [{
            path: "/news/newsSource",
            component: () => import("@/views/news/source")
          },
          {
            path: "/news/newsInformation/sourceInfo",
            component: () => import("@/views/news/source/sourceInfo"),
            name: "SourceInfo",
            meta: {
              title: "资讯来源详情",
              isMenu: true
            }
          },
          {
            path: "/news/newsInformation/recruitmentCount",
            component: () => import("@/views/news/source/consultingNumber"),
            name: "RecruitmentCount",
            meta: {
              title: "资讯个数",
              isMenu: true
            }
          },
          {
            path: "/news/newsInformation/todayAddNum",
            component: () => import("@/views/news/source/todayNumber"),
            name: "TodayAddNum",
            meta: {
              title: "今日新增个数",
              isMenu: true
            }
          },
          {
            path: "/news/newsInformation/weekNumber",
            component: () => import("@/views/news/source/weekNumber"),
            name: "weekNumber",
            meta: {
              title: "周新增数量",
              isMenu: true
            }
          }
        ]
      }
    ]
  },
  {
    path: "/scheme",
    name: "scheme",
    component: RouterView,
    redirect: "/scheme",
    meta: {
      title: "方案拆解",
      isMenu: true
    },
    children: [{
        path: "/scheme",
        name: "scheme",
        component: () => import("@/views/scheme")
      },
      {
        path: "/schemeInfo",
        component: () => import("@/views/scheme/schemeInfo"),
        name: "schemeInfo",
        meta: {
          title: "方案数据详情",
          isMenu: true
        }
      }
    ]
  },
  {
    path: "/knowledgeData",
    name: "knowledgeData",
    component: RouterView,
    redirect: "/field",
    meta: {
      title: "知识数据",
      isMenu: true
    },
    children: [{
        // 领域知识
        path: "field",
        name: "field",
        component: RouterView,
        redirect: "/knowledgeData/field",
        meta: {
          title: "领域知识",
          isMenu: true
        },
        children: [{
            path: "/knowledgeData/field",
            component: () => import("@/views/field")
          },
          {
            path: "/knowledgeData/field/fieldInfo",
            component: () => import("@/views/field/fieldInfo"),
            name: "fieldInfo",
            meta: {
              title: "领域知识详情",
              isMenu: true
            }
          }
        ]
      },
      // 技术知识
      {
        path: "technology",
        name: "technology",
        redirect: "/knowledgeData/technology",
        component: RouterView,
        meta: {
          title: "技术知识",
          isMenu: true
        },
        children: [{
            path: "/knowledgeData/technology",
            name: "technology",
            component: () => import("@/views/technology")
          },
          {
            path: "/knowledgeData/technology/technologyInfo",
            component: () => import("@/views/technology/technologyInfo"),
            name: "technologyInfo",
            meta: {
              title: "技术知识详情",
              isMenu: true
            }
          }
        ]
      },
       // 行为知识
        {
          path: "behavioral",
          name: "behavioral",
          redirect: "/knowledgeData/behavioral",
          component: RouterView,
          meta: {
            title: "行为知识",
            isMenu: true
          },
          children: [{
              path: "/knowledgeData/behavioral",
              name: "behavioral",
              component: () => import("@/views/Sceneknowledge/behavioral.vue")
            },
            {
              path: "/knowledgeData/addbehavioral",
              component: () => import("@/views/Sceneknowledge/addbehavioral.vue"),
              name: "addbehavioral",
              meta: {
                title: "行为知识",
                isMenu: true
              }
            }
           
            
          ]
        },
         // 行为知识
         {
          path: "procedures",
          name: "procedures",
          redirect: "/knowledgeData/procedures",
          component: RouterView,
          meta: {
            title: "工艺工序知识",
            isMenu: true
          },
          children: [{
              path: "/knowledgeData/procedures",
              name: "procedures",
              component: () => import("@/views/Sceneknowledge/procedures.vue")
            },
            {
              path: "/knowledgeData/addprocedures",
              component: () => import("@/views/Sceneknowledge/addprocedures.vue"),
              name: "addprocedures",
              meta: {
                title: "工艺工序知识",
                isMenu: true
              }
            }
           
            
          ]
        },
      // 产业链知识
      {
        path: "industrialChain",
        name: "industrialChain",
        redirect: "/knowledgeData/industrialChain",
        component: RouterView,
        meta: {
          title: "产业链知识",
          isMenu: true
        },
        children: [{
            path: "/knowledgeData/industrialChain",
            name: "industrialChain",
            component: () => import("@/views/industrialChain")
          },
          {
            path: "/knowledgeData/industrialChain/industrialChainInfo",
            component: () =>
              import("@/views/industrialChain/industrialChainInfo"),
            name: "industrialChainInfo",
            meta: {
              title: "产业链知识详情",
              isMenu: true
            }
          }
        ]
      },
      // 行业知识
      {
        path: "industry",
        name: "industry",
        redirect: "/knowledgeData/industry",
        component: RouterView,
        meta: {
          title: "行业知识",
          isMenu: true
        },
        children: [{
            path: "/knowledgeData/industry",
            name: "industry",
            component: () => import("@/views/industry")
          },
          {
            path: "/knowledgeData/industry/industryInfo",
            component: () => import("@/views/industry/industryInfo"),
            name: "industryInfo",
            meta: {
              title: "行业知识详情",
              isMenu: true
            }
          }
        ]
      },
      //商业知识
      {
        path: "commercial",
        name: "Commercial",
        redirect: "/knowledgeData/commercial",
        component: RouterView,
        meta: {
          title: "政府知识",
          isMenu: true
        },
        children: [{
            path: "/knowledgeData/commercial",
            name: "industry",
            component: () => import("@/views/commercial")
          },
          {
            path: "/knowledgeData/commercial/commercialInfo",
            component: () => import("@/views/commercial/dataInfo"),
            name: "commercialInfo",
            meta: {
              title: "政府知识详情",
              isMenu: true
            }
          }
        ]
      },
      // 场景知识
      {
        path: "sceneknowledge",
        name: "Sceneknowledge",
        redirect: "/knowledgeData/sceneknowledge",
        component: RouterView,
        meta: {
          title: "场景知识",
          isMenu: true
        },
        children: [{
            path: "/knowledgeData/sceneknowledge",
            name: "Sceneknowledge",
            component: () => import("@/views/Sceneknowledge")
          },
          {
            path: "/knowledgeData/sceneknowledge/tabIndex",
            component: () => import("@/views/Sceneknowledge/tabIndex.vue"),
            name: "sceneknowledgeTab",
            meta: {
              title: "场景知识",
              isMenu: true
            }
          },
          {
            path: "/knowledgeData/sceneknowledge/normalInfo",
            component: () => import("@/views/Sceneknowledge/normalInfo.vue"),
            name: "SceneknowledgeNormal",
            meta: {
              title: "通用场景详情",
              isMenu: true
            }
          },
          {
            path: "/knowledgeData/sceneknowledge/specialInfo",
            component: () => import("@/views/Sceneknowledge/specialInfo.vue"),
            name: "SceneknowledgeSpecial",
            meta: {
              title: "特殊场景详情",
              isMenu: true
            }
          }
        ]
      }
    ]
  },
  {
    path: "/system",
    name: "system",
    component: RouterView,
    redirect: "/system/user",
    meta: {
      title: "系统管理",
      isMenu: true
    },
    children: [{
        // 用户管理
        path: "user",
        name: "user",
        component: RouterView,
        redirect: "/system/user",
        meta: {
          title: "用户管理",
          isMenu: true
        },
        children: [{
          path: "/system/user",
          component: () => import("@/views/system/user")
        }]
      },
      // 角色管理
      {
        path: "system",
        name: "system",
        redirect: "/system/role",
        component: RouterView,
        meta: {
          title: "角色管理",
          isMenu: true
        },
        children: [{
          path: "/system/role",
          name: "role",
          component: () => import("@/views/system/role")
        }]
      },
      // 数据字典管理
      {
        path: "dataDictionary",
        name: "dataDictionary",
        redirect: "/system/dataDictionary",
        component: RouterView,
        meta: {
          title: "数据字典管理",
          isMenu: true
        },
        children: [{
          path: "/system/dataDictionary",
          name: "dataDictionary",
          component: () => import("@/views/system/dataDictionary")
        }]
      },
      // 个人信息
      {
        path: "personMessage",
        name: "personMessage",
        redirect: "/system/personMessage",
        component: RouterView,
        meta: {
          title: "个人信息",
          isMenu: true
        },
        children: [{
          path: "/system/personMessage",
          name: "personMessage",
          component: () => import("@/views/system/message")
        }]
      },
      // 操作日志
      {
        path: "diary",
        name: "diary",
        redirect: "/system/diary",
        component: RouterView,
        meta: {
          title: "操作日志",
          isMenu: true
        },
        children: [{
          path: "/system/diary",
          name: "diary",
          component: () => import("@/views/system/diary")
        }]
      }
    ]
  },
  {
    path: "/exercise",
    name: "Exercise",
    component: RouterView,
    redirect: "/exercise/activeData",
    meta: {
      title: "数智化活动",
      isMenu: true
    },
    children: [{
        // 活动数据
        path: "activeData",
        name: "ActiveData",
        component: RouterView,
        redirect: "/exercise/activeData",
        meta: {
          title: "活动数据",
          isMenu: true
        },
        children: [{
            path: "/exercise/activeData",
            component: () => import("@/views/Exercise/ActiveData")
          },
          {
            path: "/exercise/activeData/info",
            component: () => import("@/views/Exercise/ActiveData/dataInfo"),
            name: "ActiveDataInfo",
            meta: {
              title: "活动数据详情",
              isMenu: true
            }
          },
          {
            path: "/exercise/activeData/editSponsor",
            component: () =>
              import("@/views/Exercise/ActiveData/editInfo/editSpnor"),
            name: "EditSponsorInfo",
            meta: {
              title: "活动数据详情",
              isMenu: true
            }
          }
        ]
      },
      // 技术知识
      {
        path: "sponsor",
        name: "Sponsor",
        redirect: "/exercise/sponsor",
        component: RouterView,
        meta: {
          title: "赞助方信息",
          isMenu: true
        },
        children: [{
            path: "/exercise/sponsor",
            name: "Sponsor",
            component: () => import("@/views/Exercise/SponsorInformation")
          },
          {
            path: "/exercise/sponsor/sponsorInfo",
            component: () =>
              import("@/views/Exercise/SponsorInformation/sponsorInfo"),
            name: "SponsorInfo",
            meta: {
              title: "赞助方信息详情",
              isMenu: true
            }
          }
        ]
      },
      // 主办方信息
      {
        path: "organizer",
        name: "Organizer",
        redirect: "/exercise/organizer",
        component: RouterView,
        meta: {
          title: "主办方信息",
          isMenu: true
        },
        children: [{
            path: "/exercise/organizer",
            name: "Organizer",
            component: () => import("@/views/Exercise/OrganizerInformation")
          },
          {
            path: "/exercise/organizer/organizerInformation",
            component: () =>
              import(
                "@/views/Exercise/OrganizerInformation/OrganizerInformationData"
              ),
            name: "OrganizerInformation",
            meta: {
              title: "主办方信息详情",
              isMenu: true
            }
          }
        ]
      },
      // 行业知识
      {
        path: "guest",
        name: "Guest",
        redirect: "/exercise/guest",
        component: RouterView,
        meta: {
          title: "嘉宾信息",
          isMenu: true
        },
        children: [{
            path: "/exercise/guest",
            name: "Guest",
            component: () => import("@/views/Exercise/guestInformation")
          },
          {
            path: "/exercise/guest/guestInfo",
            component: () => import("@/views/Exercise/guestInformation/guest"),
            name: "guestInfo",
            meta: {
              title: "嘉宾信息详情",
              isMenu: true
            }
          }
        ]
      }
    ]
  },
  {
    path: "/recruit",
    name: "Recruit",
    component: RouterView,
    redirect: "/recruit",
    meta: {
      title: "招聘数据",
      isMenu: true,
      keepAlive: true
      // isBack: false
    },
    children: [{
        path: "/recruit",
        component: () => import("@/views/recruit")
      },
      // {
      //   path: "recruitInfo",
      //   name: "RecruitInfo",
      //   component: () => import("../views/recruit/recruitInfo"),
      //   meta: {
      //     title: "招聘数据详情",
      //     isMenu: true
      //   }
      // }
      {
        path: "recruitInfo",
        name: "RecruitInfo",
        component: () => import("../views/recruit/tabIndex"),
        meta: {
          title: "招聘数据详情",
          isMenu: true
        }
      }
    ]
  },
  {
    path: "/paper",
    name: "Paper",
    component: RouterView,
    redirect: "/paper/info",
    meta: {
      title: "数智论文",
      isMenu: true
    },
    children: [{
        path: "info",
        name: "Info",
        component: RouterView,
        redirect: "/paper/info",
        meta: {
          title: "论文数据",
          isMenu: true
        },
        children: [{
            path: "/paper/info",
            component: () => import("../views/paper/info")
          },
          {
            path: "/paper/add",
            name: "Add",
            component: () => import("../views/paper/info/add"),
            meta: {
              title: "新增论文数据",
              isMenu: true
            }
          }
        ]
      },
      {
        path: "/periodical",
        name: "Periodical",
        component: RouterView,
        redirect: "/paper/periodical",
        meta: {
          title: "会议期刊",
          isMenu: true
        },
        children: [{
            path: "/paper/periodical",
            component: () => import("../views/paper/periodical")
          },
          {
            path: "/paper/addPeriodical",
            name: "AddPeriodical",
            component: () =>
              import("../views/paper/periodical/AddPeriodical.vue"),
            meta: {
              title: "新增会议期刊",
              isMenu: true
            }
          }
        ]
      },
      {
        path: "/author",
        name: "Author",
        component: RouterView,
        redirect: "/paper/author",
        meta: {
          title: "论文作者",
          isMenu: true
        },
        children: [{
          path: "/paper/author",
          component: () => import("../views/paper/author")
        }]
      },
      {
        path: "/organization",
        name: "Organization",
        component: RouterView,
        redirect: "/paper/organization",
        meta: {
          title: "论文机构",
          isMenu: true
        },
        children: [{
          path: "/paper/organization",
          component: () => import("../views/paper/organization")
        }]
      }
    ]
  },
  // 产权资质模块
  {
    path: "/equity",
    name: "Equity",
    component: RouterView,
    redirect: "/equity/equityList",
    meta: {
      title: "产权资质",
      isMenu: true
    },
    children: [{
        // 专利
        path: "/equityList",
        name: "EquityList",
        component: RouterView,
        redirect: "/equity/equityList",
        meta: {
          title: "专利",
          isMenu: true
        },
        children: [{
            path: "/equity/equityList",
            component: () => import("@/views/equity")
          },
          {
            path: "/equity/EquityList/equityInfo",
            component: () => import("@/views/equity/info"),
            name: "EquityInfo",
            meta: {
              title: "专利信息",
              isMenu: true
            }
          }
        ]
      },
      // 软件著作权
      {
        path: "/copyright",
        name: "CopyRight",
        redirect: "/equity/copyright",
        component: RouterView,
        meta: {
          title: "软件著作权",
          isMenu: true
        },
        children: [{
            path: "/equity/copyright",
            name: "CopyRight",
            component: () => import("@/views/copyright")
          },
          {
            path: "/equity/copyright/copyrightInfo",
            component: () => import("@/views/copyright/info.vue"),
            name: "CopyRightInfo",
            meta: {
              title: "软件著作权信息",
              isMenu: true
            }
          }
        ]
      }
    ]
  },
  {
    path: "/404",
    component: () => import("@/views/errorPage/404/index.vue"),
    hidden: true
  },
  // 客户企业
  {
    path: "customerCompany",
    name: "CustomerCompany",
    redirect: "/customerCompany",
    component: RouterView,
    meta: {
      title: "智参企业",
      isMenu: true
    },
    children: [{
        path: "/customerCompany",
        name: "CustomerCompany",
        component: () => import("@/views/CustomerCompany")
      },
      {
        path: "/customerCompany/customerMain",
        component: () => import("@/views/CustomerCompany/customerMain"),
        name: "customerMain",
        meta: {
          title: "智参企业详情",
          isMenu: true
        }
      }
      // {
      //   path: "/customerCompany/info",
      //   component: () => import("@/views/CustomerCompany/info"),
      //   name: "CustomerCompanyInfo",
      //   meta: {
      //     title: "客户企业详情",
      //     isMenu: true
      //   }
      // }
    ]
  },
  // 标准知识
  {
    path: "/standardKnowledge",
    name: "StandardKnowledge",
    component: RouterView,
    redirect: "/standardKnowledge/governmentStandard",
    meta: {
      title: "标准知识",
      isMenu: true
    },
    children: [{
        path: "governmentStandard",
        name: "GovernmentStandard",
        component: RouterView,
        redirect: "/standardKnowledge/governmentStandard",
        meta: {
          title: "国家标准",
          isMenu: true
        },
        children: [{
            path: "/standardKnowledge/governmentStandard",
            component: () =>
              import("../views/standardKnowledge/governmentStandard")
          },
          {
            path: "/standardKnowledge/governmentStandard/info",
            name: "GovernmentStandardInfo",
            component: () =>
              import("../views/standardKnowledge/governmentStandard/info.vue"),
            meta: {
              title: "国家标准详情",
              isMenu: true
            }
          }
        ]
      },
      {
        path: "IndustrialStandard",
        name: "IndustrialStandard",
        component: RouterView,
        redirect: "/standardKnowledge/governmentStandard",
        meta: {
          title: "行业标准",
          isMenu: true
        },
        children: [{
            path: "/standardKnowledge/industrialStandard",
            component: () =>
              import("../views/standardKnowledge/industrialStandard")
          },
          {
            path: "/standardKnowledge/industrialStandard/info",
            name: "IndustrialStandardInfo",
            component: () =>
              import("../views/standardKnowledge/industrialStandard/info.vue"),
            meta: {
              title: "行业标准详情",
              isMenu: true
            }
          }
        ]
      },
      {
        path: "localStandard",
        name: "LocalStandard",
        component: RouterView,
        redirect: "/standardKnowledge/localStandard",
        meta: {
          title: "地方标准",
          isMenu: true
        },
        children: [{
            path: "/standardKnowledge/localStandard",
            component: () => import("../views/standardKnowledge/localStandard")
          },
          {
            path: "/standardKnowledge/localStandard/info",
            name: "LocalStandardInfo",
            component: () =>
              import("../views/standardKnowledge/localStandard/info.vue"),
            meta: {
              title: "地方标准详情",
              isMenu: true
            }
          }
        ]
      },
      {
        path: "groupStandards",
        name: "GroupStandards",
        component: RouterView,
        redirect: "/standardKnowledge/groupStandards",
        meta: {
          title: "团体标准",
          isMenu: true
        },
        children: [{
            path: "/standardKnowledge/groupStandards",
            component: () => import("../views/standardKnowledge/groupStandards")
          },
          {
            path: "/standardKnowledge/groupStandards/info",
            name: "GroupStandardsInfo",
            component: () =>
              import("../views/standardKnowledge/groupStandards/info.vue"),
            meta: {
              title: "团体标准详情",
              isMenu: true
            }
          }
        ]
      },
      {
        path: "companyStandard",
        name: "CompanyStandard",
        component: RouterView,
        redirect: "/standardKnowledge/companyStandard",
        meta: {
          title: "企业标准",
          isMenu: true
        },
        children: [{
            path: "/standardKnowledge/companyStandard",
            component: () =>
              import("../views/standardKnowledge/companyStandard")
          },
          {
            path: "/standardKnowledge/companyStandard/info",
            name: "CompanyStandardInfo",
            component: () =>
              import("../views/standardKnowledge/companyStandard/info.vue"),
            meta: {
              title: "企业标准详情",
              isMenu: true
            }
          }
        ]
      },
      {
        path: "internationalStandard",
        name: "InternationalStandard",
        component: RouterView,
        redirect: "/standardKnowledge/internationalStandard",
        meta: {
          title: "国际标准",
          isMenu: true
        },
        children: [{
            path: "/standardKnowledge/internationalStandard",
            component: () =>
              import("../views/standardKnowledge/internationalStandard")
          },
          {
            path: "/standardKnowledge/internationalStandard/info",
            name: "InternationalStandardInfo",
            component: () =>
              import(
                "../views/standardKnowledge/internationalStandard/info.vue"
              ),
            meta: {
              title: "国际标准详情",
              isMenu: true
            }
          }
        ]
      },
      {
        path: "foreignStandards",
        name: "ForeignStandards",
        component: RouterView,
        redirect: "/standardKnowledge/foreignStandards",
        meta: {
          title: "国外标准",
          isMenu: true
        },
        children: [{
            path: "/standardKnowledge/foreignStandards",
            component: () =>
              import("../views/standardKnowledge/foreignStandards")
          },
          {
            path: "/standardKnowledge/foreignStandards/info",
            name: "foreignStandardsInfo",
            component: () =>
              import("../views/standardKnowledge/foreignStandards/info.vue"),
            meta: {
              title: "国外标准详情",
              isMenu: true
            }
          }
        ]
      }
    ]
  },
  // 标签管理
  {
    path: '/label',
    name: 'Label',
    component: RouterView,
    redirect: "/label/management",
    meta: {
      title: "标签管理",
      isMenu: true
    },
    children: [{
        path: '/label/management',
        component: () =>
          import("../views/label")
      },
      {
        path: 'label/addlabel',
        name: 'addlabel',
        component: () =>
          import("../views/label/add.vue")
      }
    ]
  },
  // 新增专家模块
  {
    path: "/expert",
    name: "Expert",
    component: RouterView,
    redirect: "/expert ",
    meta: {
      title: "专家数据",
      isMenu: true
    },
    children: [{
        path: "/expert",
        component: () => import("../views/expert")
      },
      {
        path: "/expert/index",
        name: "expert",
        component: () => import("../views/expert/index.vue"),
        meta: {
          title: "专家信息",
          isMenu: true
        }
      },
      {
        path: '/expert/expertInfo',
        name: 'expertInfo',
        component: () => import('../views/expert/expertInfo/index.vue'),
        meta: {
          title: '专家管理',
          isMenu: true
        }
      },
    ]
  },
];
export default menuList;
